var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sensitive-word-List" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: "",
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "导入", permission: "import" },
                  on: { click: _vm.importData },
                }),
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.createData },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "敏感词" },
                  model: {
                    value: _vm.searchParams.sensitiveWord,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sensitiveWord", $$v)
                    },
                    expression: "searchParams.sensitiveWord",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "分类", options: _vm.typeOps },
                  model: {
                    value: _vm.searchParams.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "type", $$v)
                    },
                    expression: "searchParams.type",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    maxDate: _vm.maxDate,
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startInTime,
                    endTime: _vm.searchParams.endInTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startInTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startInTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endInTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endInTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.updateData(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData(scope.row)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    permission: "delete",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }