<template>
  <div class="sensitive-word-List">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          isMultiSelect>
      <template #headerSlot>
        <v-button text="导入"
                  permission="import"
                  @click="importData" />
        <v-button text="新增"
                  permission="add"
                  @click="createData" />
      </template>
      <template #searchSlot>
        <v-input label="敏感词"
                 v-model="searchParams.sensitiveWord" />
        <v-select label="分类"
                  v-model="searchParams.type"
                  :options="typeOps" />
        <v-datepicker label="创建时间"
                      :maxDate="maxDate"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.startInTime"
                      :endTime.sync="searchParams.endInTime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="updateData(scope.row)" />
        <v-button text="删除"
                  type="text"
                  permission="delete"
                  @click="deleteData(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除"
                  permission="delete"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData)" />
      </template>
    </list>
  </div>
</template>

<script>
import moment from 'moment'
import { getListURL, exportListURL, getTypeListURL, deleteURL, batchDeleteURL } from './api'

export default {
  name: 'SensitiveWordList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      typeOps: [],
      maxDate: new Date(),
      searchParams: {
        sensitiveWord: '',
        type: undefined,
        startInTime: moment().subtract(6, 'M').format('YYYY-MM-DD HH:mm'),
        endInTime: moment().format('YYYY-MM-DD HH:mm')
      },
      headers: [
        {
          prop: 'sensitiveWord',
          label: '敏感词'
        },
        {
          prop: 'typeName',
          label: '分类'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  created () {
    this.getTypeList()
  },
  methods: {
    async getTypeList () {
      const { status, data } = await this.$axios.get(getTypeListURL)
      if (status === 100) {
        if (data && data.length) {
          data.forEach((item) => {
            item['text'] = item.value
            item['value'] = item.code
          })
          data.unshift({
            text: '全部',
            value: undefined
          })
          this.typeOps = data
        }
      }
    },
    importData () {
      this.$router.push({
        name: 'sensitiveWordFormImport'
      })
    },
    createData () {
      this.$router.push({
        name: 'sensitiveWordForm'
      })
    },
    updateData (row) {
      this.$router.push({
        name: 'sensitiveWordForm',
        query: {
          id: row.id
        }
      })
    },
    async batch (selected) {
      let result = await this.$confirm('确认删除？', {
        title: '提示'
      })
      if (result) {
        // 操作日志添加操作对象参数,组装规则：敏感词-分类
        let dataObject = ''
        let array = selected.data.map(item => {
          return {
            sensitiveWord: item.sensitiveWord,
            typeName: item.typeName
          }
        })
        array.forEach(item => dataObject += `${item.sensitiveWord}-${item.typeName};`)
        dataObject = dataObject.substring(0, dataObject.length - 1)

        const params = {
          ids: JSON.stringify(selected.ids),
          dataObject: dataObject
        }
        const { status, data } = await this.$axios.delete(batchDeleteURL, { params })
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    async deleteData (row) {
      const result = await this.$confirm('确认删除？', {
        title: '提示'
      })
      if (result) {
        const url = `${deleteURL}/${row.id}`
        const { status, data } = await this.$axios.delete(url,
          {
            data: {
              dataObject: `${row.sensitiveWord}-${row.typeName}`
            }
          }
        )
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    }
  }
}
</script>
